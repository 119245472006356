<template>
  <div class="card-container">
    <div>
      <h3>{{ title }}</h3>

      <p v-if="reviewed">{{ score }}</p>
      <p v-if="given && !reviewed">Score Not evaluated Yet</p>
    </div>
    <div v-if="given" class="icons">
      <div @click="myResponses" title="My responses">
        <button class="btn btnbtn btn1">My Responses</button>
      </div>
      <div @click="showStats" title="View Stats">
        <button class="btn btnbtn btn1" >Show Stats</button>
      </div>
    </div>
    <div class="icons" v-else @click="giveTest">
      <div title="Give Test">
        <button class="btn btnbtn btn2">Start Test</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimplifiedCard",
  props: ["comp", "page_id"],
  data() {
    return {
      test_id: this.comp.id,
      title: this.comp.title,
      score: this.comp.score + "/" + this.comp.total,
      date: this.comp.date,
      given: this.comp.given,
      reviewed: this.comp.reviewed
    };
  },
  methods: {
    showStats() {
      console.log(this.page_id);
      console.log(this.test_id);
      this.$router.push({
        name: "Stats",
        params: {
          id: this.test_id,
          page_id: this.page_id
        }
      });
    },
    giveTest() {
      this.$router.push({
        name: "Instructions",
        params: {
          test_id: this.test_id,
          page_id: this.page_id
        }
      });
    },
    myResponses() {
      this.$router.push({
        name: "MyResponses",
        params: {
          test_id: this.test_id,
          page_id: this.page_id
        },
        query: {
          test_id: this.test_id,
          page_id: this.page_id
        }
      });
    }
  }
};
</script>

<style scoped>
.startbutton {
  border: none;
  background-color: rgb(242, 227, 227);
  cursor: pointer;
  color: #c11919;
  font-size: 17px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  text-decoration: underline;
}
.btnbtn {
  background-color: #dfdfdf;
}
.btn1 {
  margin-right: 5px;
}
.btn2 {
  margin-left: 70px;
}
.startbutton:focus {
  border: none;
  outline: none;
}
.iconify:hover {
  color: #c11919;
}
.iconify {
  margin-left: 10px;
}
.card-container {
  border-radius: 40px;
  transition: 0.45s;
  width: 300px;
  height: 250px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 40px 50px;
  box-shadow: 10px 10px 2px rgb(110 0 0 / 25%);
  position: relative;
  margin: 50px 10px;
  background-color: rgb(255, 255, 255);
}
.card-container:hover {
  transform: scale(0.9, 0.9);
  border-radius: 50px;
  box-shadow: 10px 10px 5px 6px rgba(110, 0, 0, 0.25);
}
.icons {
  padding: 10px;
  width: 100px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: 20px;
}
svg {
  margin-right: 10px;
}
* {
  color: rgb(148, 22, 0);
  text-align: center;
}
h2 {
  margin-bottom: 10px;
}
</style>
